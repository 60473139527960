import React from 'react'
import {
  Action,
  Box,
  Placeholder,
  Subheader,
  Text,
  TextSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { BulletPointTextArea } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/BulletPointTextArea'
import {
  useGenerateInterviewSummary,
  useGetInterviewFeedbackTranscript,
} from '@src/api/recruitment/interviewFeedback'
import { RateSummary } from '@src/pages/Forms/InterviewFeedback/General/AIInterviewSummary/RateSummary'

interface AISummaryProps {
  interviewFeedbackId: number | string
}

export const AISummary = ({ interviewFeedbackId }: AISummaryProps) => {
  const {
    data,
    isLoading: loadingInterviewFeedbackTranscript,
    refetch: refetchInterviewFeedbackTranscript,
  } = useGetInterviewFeedbackTranscript(interviewFeedbackId)

  const { mutateAsync: generateInterviewSummary, isLoading: loadingGenerateSummary } =
    useGenerateInterviewSummary()

  const isLoading = loadingInterviewFeedbackTranscript || loadingGenerateSummary
  const hasSummary = !!data?.summary
  const hasTranscript = !!data?.transcript

  const handleGenerateSummary = async () => {
    await generateInterviewSummary(interviewFeedbackId)
    refetchInterviewFeedbackTranscript()
  }

  return (
    <Widget pb="s-8">
      <Box px="s-16">
        {!hasSummary && (
          <Subheader>
            <Subheader.Title>
              <Text color={Token.color.foreground} variant="emphasis1">
                Summary
              </Text>
            </Subheader.Title>
          </Subheader>
        )}
        {isLoading && (
          <VStack gap="s-4" pt="s-16" pb="s-8">
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
          </VStack>
        )}
      </Box>
      {!isLoading && (
        <>
          {hasSummary ? (
            <>
              <BulletPointTextArea value={data?.summary} disabled />
              <RateSummary interviewId={interviewFeedbackId} />
            </>
          ) : hasTranscript ? (
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
                }}
              />
              <Placeholder.Title>There's no summary for this interview</Placeholder.Title>
              <Placeholder.Actions>
                <Action onClick={handleGenerateSummary}>Generate summary</Action>
              </Placeholder.Actions>
            </Placeholder>
          ) : (
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
                }}
              />
              <Placeholder.Title>
                There's no transcript for this interview
              </Placeholder.Title>
            </Placeholder>
          )}
        </>
      )}
    </Widget>
  )
}
