import React from 'react'
import {
  Tile,
  Avatar,
  Tag,
  IconButton,
  Text,
  LineClamp,
  Ellipsis,
  Token,
  HStack,
  VStack,
} from '@revolut/ui-kit'
import { formatTimeAgo } from '@src/utils/format'
import { compareAsc, formatDistanceToNow } from 'date-fns'
import {
  AnnouncementBackgroundIcon,
  getAnnouncementBackgroundIconUrl,
  getDefaultBackgroundColor,
  INTERNAL_BACKGROUNDS,
  INTERNAL_BACKGROUNDS_LIGHT,
} from '@src/pages/Forms/NotificationTemplate/common'
import { useIsCommercialProduct } from '@src/hooks/useIsCommercialProduct'

interface AnnouncementTileProps {
  onClick: () => void
  dismiss: () => void
  color?: string
  showIcon: boolean
  pinned: boolean
  icon?: string | null
  backgroundIcon?: AnnouncementBackgroundIcon | null
  backgroundImage?: string
  senderName?: string
  title: string
  description?: string
  date?: string
  deadline?: string | null
}

export const AnnouncementTile = ({
  onClick,
  color,
  icon,
  senderName,
  showIcon,
  backgroundIcon,
  backgroundImage,
  pinned,
  dismiss,
  title,
  description,
  date,
  deadline,
}: AnnouncementTileProps) => {
  const isCommercial = useIsCommercialProduct()

  const textColor =
    color && INTERNAL_BACKGROUNDS_LIGHT.includes(color) ? Token.color.black : undefined
  const image =
    backgroundImage ||
    (backgroundIcon ? getAnnouncementBackgroundIconUrl(backgroundIcon) : undefined)
  const isWithCustomGradient = color && INTERNAL_BACKGROUNDS.includes(color)

  return (
    <Tile
      variant="small"
      onClick={onClick}
      image={isWithCustomGradient ? undefined : image}
      bg={color || getDefaultBackgroundColor({ isCommercial })}
      preserveAspectRatio={false}
      color={textColor}
      style={
        isWithCustomGradient
          ? {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%), url(${image})`,
              backgroundSize: 'cover',
            }
          : {}
      }
      data-testid="announcement-tile"
    >
      {showIcon && icon ? (
        <Tile.Avatar>
          <HStack align="center" space="s-8" width="300px">
            <Avatar variant="brand" image={icon} bg={Token.color.white_30} size={24} />
            {senderName ? <Text>{senderName}</Text> : null}
          </HStack>
        </Tile.Avatar>
      ) : null}
      {pinned ? (
        <Tile.Action>
          <Tag useIcon="Pin" variant="outlined" color={textColor || Token.color.white}>
            pinned
          </Tag>
        </Tile.Action>
      ) : (
        <Tile.Action>
          <IconButton
            aria-label="Remove"
            useIcon="Cross"
            size={16}
            color={Token.color.white_60}
            onClick={dismiss}
          />
        </Tile.Action>
      )}
      <Tile.Title>{title}</Tile.Title>
      <Tile.Description>
        <VStack space="s-4" maxWidth="68%">
          {description ? <LineClamp max={2}>{description}</LineClamp> : null}
          <Ellipsis>
            <Text variant="caption" color={textColor || Token.color.grey10}>
              {deadline
                ? `${formatDistanceToNow(new Date(deadline))} ${
                    compareAsc(Date.now(), new Date(deadline)) === 1 ? `overdue` : `left`
                  }`
                : date
                ? formatTimeAgo(date)
                : ''}
            </Text>
          </Ellipsis>
        </VStack>
      </Tile.Description>
    </Tile>
  )
}
