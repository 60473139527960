import React from 'react'
import { Group, Icon, Item, Link, Subheader, Token } from '@revolut/ui-kit'

interface Props {
  playbookUrls: string[]
  skillName: string | undefined
}

export const SkillPlaybooks = ({ playbookUrls, skillName }: Props) => {
  return (
    <>
      <Subheader variant="default">Skill playbooks</Subheader>
      <Group>
        {playbookUrls.map(playbook_url => (
          <Item
            key={playbook_url}
            use={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={playbook_url}
          >
            <Item.Content>
              <Item.Title color={Token.color.foreground}>{skillName}</Item.Title>
              <Item.Description>Skill playbook</Item.Description>
            </Item.Content>
            <Item.Side>
              <Icon name="LinkExternal" color={Token.color.foreground} />
            </Item.Side>
          </Item>
        ))}
      </Group>
    </>
  )
}
