import React from 'react'
import { Text } from '@revolut/ui-kit'

import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { useGetSurveyAnalytics } from '../api/analytics'
import { EngagementSurveyAnalyticsInterface } from '../api/analytics/interfaces'
import { normalizedScoreToColors } from '../helpers/statValuesToColor'

interface ScorePreviewProps {
  analyticsData: EngagementSurveyAnalyticsInterface | undefined
  isLoading: boolean
}
const ScorePreview = ({ analyticsData, isLoading }: ScorePreviewProps) => {
  if (isLoading) {
    return null
  }
  return (
    <Text color={normalizedScoreToColors(analyticsData?.average_score)?.color}>
      {analyticsData?.average_score ?? 'n/a'}
    </Text>
  )
}

export const useGetEngagementScorePreview = (
  enabled: boolean,
  scopeFilters: FilterByInterface[],
) => {
  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted, undefined, {
      enabled,
    })
  const latestSurvey = surveyOptions?.[0]
  const { data: surveyAnalyticsData, isLoading: isLoadingSurveyAnalyticsData } =
    useGetSurveyAnalytics(latestSurvey?.id, scopeFilters, enabled)
  const isLoading = isLoadingSurveyOptions || isLoadingSurveyAnalyticsData

  return {
    isLoading,
    scoreValue: surveyAnalyticsData?.average_score,
    scorePreview: (
      <ScorePreview analyticsData={surveyAnalyticsData} isLoading={isLoading} />
    ),
  }
}
