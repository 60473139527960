import React from 'react'
import {
  Action,
  Avatar,
  Flex,
  Icon,
  Item,
  HStack,
  Progress,
  Text,
  Token,
  Widget,
  Placeholder,
} from '@revolut/ui-kit'
import { useGetCompensationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Tooltip from '@components/Tooltip/Tooltip'
import { EmployeeTotalCompensationStatsInterface } from '@src/interfaces/totalCompensation'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { PieChart } from '../Charts/PieChart'
import { BENEFITS_LABEL, ON_TARGET_BONUS_LABEL } from './CompensationOverTime'
import { getTotalEquityTooltipText } from './helpers'

type Props = {
  compensationParams: CompensationParams
  id: number
  data?: EmployeeTotalCompensationStatsInterface
  isLoading: boolean
  height: string
  minHeight: number
}

export const CompensationTotal = ({
  data,
  compensationParams,
  id,
  isLoading,
  height,
  minHeight,
}: Props) => {
  const isCommercial = useIsCommercial()
  const { data: compensationSettings } = useGetCompensationSettings()
  const { setCurrency } = compensationParams

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Base salary',
      value: data ? Number(data.current_year_base_salary) : 0,
      color: Token.colorChannel.blue,
    },
    {
      name: 'Vesting equity',
      value: data ? Number(data.equity_vesting_in_the_next_12_month) : 0,
      color: Token.colorChannel.cyan,
    },
    {
      name: 'Cash bonus',
      value: data ? Number(data.cash_bonus_in_the_next_12_month) : 0,
      color: Token.colorChannel.pink,
    },
    {
      name: BENEFITS_LABEL,
      value: data ? Number(data.benefits_contribution) : 0,
      color: Token.colorChannel.yellow,
    },
    {
      name: ON_TARGET_BONUS_LABEL,
      value: data ? Number(data.on_target_cash_bonus) : 0,
      color: Token.colorChannel.orange,
      isProjected: true,
    },
  ].filter(({ value }) => !!value)

  const legendTooltipData = compensationSettings?.on_target_bonus_description
    ? [
        {
          key: ON_TARGET_BONUS_LABEL,
          tooltip: (
            <Text whiteSpace="pre-wrap">
              {compensationSettings.on_target_bonus_description}
            </Text>
          ),
        },
      ]
    : undefined

  const total = data
    ? Number(data.current_year_base_salary) +
      Number(data.equity_vesting_in_the_next_12_month) +
      Number(data.cash_bonus_in_the_next_12_month) +
      Number(data.on_target_cash_bonus)
    : undefined

  const progressValue = data
    ? Number(data.total_vested_equity) / Number(data.total_equity)
    : 0

  const totalEquityTooltipText = getTotalEquityTooltipText(!!isCommercial)

  if (!total && !progressValue) {
    return (
      <Widget height={height} minHeight={minHeight} p="s-16">
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Placeholder>
            <Placeholder.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D086',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
              }}
            />
            <Placeholder.Title>No compensation</Placeholder.Title>
            <Placeholder.Description>
              There is no compensation expected for the next 12 months
            </Placeholder.Description>
          </Placeholder>
        </Flex>
      </Widget>
    )
  }

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height={height}
      minHeight={minHeight}
      gap="s-16"
      data-testid="total_compensation_section"
    >
      <Widget flex={1} p="s-16">
        <Flex alignItems="stretch" flexDirection="column" height="100%">
          <Flex alignItems="center" justifyContent="space-between" gap="s-6">
            <HStack gap="s-4">
              <Text variant="emphasis2">Total compensation in next 12 months</Text>
              <Tooltip
                placement="bottom"
                text="Your estimated total compensation & benefits for the next 12 rolling months."
              >
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Flex flex={1} justifyContent="flex-end">
              <Action
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.SALARY, {
                  id: String(id),
                })}
                use={InternalLink}
              >
                Detail view
              </Action>
            </Flex>
          </Flex>

          <CurrencySelect
            label=""
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            value={data?.currency.iso_code}
          />

          <PieChart
            currency={data?.currency.iso_code}
            data={pieChartData}
            isLoading={isLoading}
            legendTooltipData={legendTooltipData}
            total={total}
          />
        </Flex>
      </Widget>

      {data && !!Number(data.total_equity) && (
        <Widget p="s-16">
          <Flex alignItems="center" justifyContent="space-between">
            <HStack gap="s-4">
              <Text variant="emphasis2">Total equity</Text>
              <Tooltip placement="bottom" text={totalEquityTooltipText}>
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.EQUITY, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" my="s-8">
            <Text color={Token.color.accent} variant="body2">
              Vested
            </Text>
            <Text variant="heading3">
              {formatMoney(Number(data.total_equity), data.currency.iso_code)}
            </Text>
            <Text color={Token.color.greyTone50} variant="body2">
              Unvested
            </Text>
          </Flex>
          <Progress value={progressValue}>
            <Progress.Label color={Token.color.accent}>
              {formatMoney(Number(data.total_vested_equity), data.currency.iso_code)}
            </Progress.Label>
            <Progress.Label>
              {formatMoney(Number(data.total_unvested_equity), data.currency.iso_code)}
            </Progress.Label>
          </Progress>
          {!!Number(data.total_sold_equity) && (
            <Text color={Token.color.greyTone50} mt="s-8" use="div" variant="caption">
              Sold equity:{' '}
              {formatMoney(Number(data.total_sold_equity), data.currency.iso_code)}
            </Text>
          )}
        </Widget>
      )}
      {data && !!Number(data.total_cash_bonus) && (
        <Widget>
          <Flex alignItems="center" justifyContent="space-between" pt="s-16" px="s-16">
            <HStack gap="s-4">
              <Text variant="emphasis2">Cash bonus</Text>
              <Tooltip placement="bottom" text="All cash bonuses awarded.">
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.BONUSES, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="CreditBag" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Historical cash bonus</Item.Title>
            </Item.Content>
            <Item.Side>
              {formatMoney(Number(data.total_cash_bonus), data.currency.iso_code)}
            </Item.Side>
          </Item>
        </Widget>
      )}
    </Flex>
  )
}
