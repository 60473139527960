import React, { useState } from 'react'
import { MoreButton, Token, useToggle } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { cancelSurveyRound, closeSurveyRound } from '@src/api/engagement'
import { ROUTES } from '@src/constants/routes'
import {
  EngagementSurveyInterface,
  EngagementSurveyRoundInterface,
} from '@src/interfaces/engagement'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { RoundForm } from './RoundForm'
import { StopRunConfirmation } from './StopRunConfirmation'
import { RoundPopupVariant } from './types'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'

interface Props {
  surveyId: number
  round: EngagementSurveyRoundInterface
  refetch: VoidFunction
  permissions: EngagementSurveyInterface['field_options']['permissions']
  onPreview?: VoidFunction
  isPending?: boolean
  placeholder?: React.ReactNode
}
export const RoundActions = ({
  surveyId,
  round,
  refetch,
  permissions,
  onPreview,
  isPending,
  placeholder,
}: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)

  const [isOpen, toggleIsOpen] = useToggle()
  const [popupType, setPopupType] = useState<RoundPopupVariant>()

  const canStop =
    round.status?.id === 'ongoing' &&
    permissions?.includes(PermissionTypes.CancelEngagementSurvey)

  const canCancel =
    round.status.id === 'upcoming' &&
    permissions?.includes(PermissionTypes.CancelEngagementSurvey)

  const canPreview =
    (onPreview && round.status?.id === 'ongoing') || round.status?.id === 'upcoming'

  const canChangeEndDate =
    round.status?.id === 'ongoing' &&
    permissions?.includes(PermissionTypes.ChangeEngagementSurvey)

  const canChangeSchedule =
    round.status?.id === 'upcoming' &&
    permissions?.includes(PermissionTypes.ChangeEngagementSurvey)

  const canSeeResults = !isEngagementDev && round.status?.id === 'completed'

  if (
    ![canStop, canPreview, canChangeEndDate, canChangeSchedule, canSeeResults].some(
      Boolean,
    )
  ) {
    return placeholder ? <>{placeholder}</> : null
  }
  return (
    <MoreButton
      open={isOpen}
      onToggle={toggleIsOpen}
      variant={isEngagementDev ? undefined : 'icon'}
      color={Token.color.accent}
      placement="auto"
    >
      {canPreview && (
        <MoreButton.Action pending={isPending} useIcon="EyeShow" onClick={onPreview}>
          Preview
        </MoreButton.Action>
      )}
      {canChangeEndDate && (
        <MoreButton.Action useIcon="TimeOutline" onClick={() => setPopupType('end')}>
          Change end date
        </MoreButton.Action>
      )}
      {canSeeResults && (
        <MoreButton.Action
          useIcon="Wealth"
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES, {
                id: surveyId,
              }),
              { roundId: round.id },
            )
          }}
        >
          Results
        </MoreButton.Action>
      )}
      {canChangeSchedule && (
        <MoreButton.Action
          useIcon="TimeOutline"
          onClick={() => setPopupType('reschedule')}
        >
          Change schedule
        </MoreButton.Action>
      )}
      {canCancel && (
        <MoreButton.Action
          variant="negative"
          useIcon="Cross"
          onClick={() => setPopupType('cancel')}
        >
          Cancel
        </MoreButton.Action>
      )}
      {canStop && (
        <MoreButton.Action
          variant="negative"
          useIcon="Cross"
          onClick={() => setPopupType('stop')}
        >
          Stop
        </MoreButton.Action>
      )}
      <StopRunConfirmation
        refetch={refetch}
        surveyId={surveyId}
        roundId={round.id}
        actionFn={popupType === 'stop' ? closeSurveyRound : cancelSurveyRound}
        isOpen={popupType === 'stop' || popupType === 'cancel'}
        onClose={() => setPopupType(undefined)}
      />
      <RoundForm
        type={popupType}
        refetch={refetch}
        surveyId={surveyId}
        roundId={round.id}
        isOpen={popupType === 'reschedule' || popupType === 'end'}
        onClose={() => setPopupType(undefined)}
      />
    </MoreButton>
  )
}
