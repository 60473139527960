import React from 'react'
import isNil from 'lodash/isNil'
import upperFirst from 'lodash/upperFirst'
import {
  CellTypes,
  ColumnCellInterface,
  ColumnInterface,
  FilterType,
} from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  KpiGoals,
  KpiInterface,
  KPIPerformanceTypes,
  KpiRelatedDashboardsInterface,
  UpdateTypes,
} from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import {
  ColoredPercent,
  getPercentColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import Icon from '@components/Icon/Icon'
import { PercentageWrapper } from '@components/CommonSC/TableStatistics'
import ParentLink from '@components/ColumnInserts/ParentLink/ParentLink'
import Tooltip from '@components/Tooltip/Tooltip'
import { ColoredText } from '@components/CommonSC/General'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { formatNumber } from '@src/utils/format'
import { KPIRuleInterface, KPITemplateInterface } from '@src/interfaces/KpiTemplates'
import KpiEnforce from '@components/ColumnInserts/KPIEnforce/KPIEnforce'
import { Box, Color, Flex, Text, TextButton, Token } from '@revolut/ui-kit'
import { getEntity, getInheritanceLevel, kpiRuleTemplateSelector } from '@src/utils/kpi'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import {
  ArrowExchange,
  ArrowRepeat,
  CalendarCheck,
  InfoOutline,
  Profile,
  Form,
} from '@revolut/icons'
import LongNumberCell from '@src/components/Table/AdvancedCells/LongNumberCell/LongNumberCell'
import { EditableColumnInterface } from '@components/TableV2/EditableTable/EditableTable'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import KPINameWithSQLError from '@components/ColumnInserts/KPINameWithSQLError/KPINameWithSQLError'
import styled from 'styled-components'
import CalibratedTooltip from '@components/ColumnInserts/CalibratedTooltip/CalibratedTooltip'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import { CompanyGoalInterface } from '@src/interfaces/companyGoals'
import {
  getDeliverableOptionColors,
  getDeliverableRatingByFinalGrade,
} from '@src/interfaces/performance'
import { getGradeEvaluationCriterionByDeliverablesProgress } from '@src/utils/performance'
import { GoalsInterface } from '@src/interfaces/goals'
import Table from '@src/components/TableV2/Table'
import { GoalMetricCell } from '@src/components/GoalMetricCell/GoalMetricCell'
import { isNumber } from 'lodash'
import { GradeEvaluationCriterion } from '@src/interfaces/settings'
import { TableCell } from '@src/components/TableV2/NewStaticTable'
import { DeliverablesEvaluationCriteriaExplanationMarkdown } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/DeliverablesHelp'

export const DraftWrap = styled.span`
  padding-right: 6px;
  color: ${Token.color.greyTone50};
`

export const kpiNameColumn: ColumnCellInterface<KpiInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.none,
  title: 'KPI',
  width: 500,
}

export const kpiGenericNameColumn: ColumnCellInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.kpi_names,
  title: 'Metric name',
  dynamicHyperlinks: data =>
    data.id ? pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: data.id }) : null,
  insert: ({ data }) => (
    <KPINameWithSQLError error={data.extra?.error}>
      <>
        {data.status === Statuses.draft && <DraftWrap>Draft</DraftWrap>}
        {data.name}
      </>
    </KPINameWithSQLError>
  ),
  width: 500,
}

export const kpiLevelColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'type',
  dataPoint: 'type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Level',
  insert: ({ data }) => getInheritanceLevel(data || {}),
}

export const kpiEntityColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Organisational unit',
  insert: ({ data }) => {
    const base = (data.is_inherited ? data.parent : data) || {}
    const entityName = getEntity(base) || '-'
    if (entityName === '-') {
      return '-'
    }
    return <TableCell>{entityName}</TableCell>
  },
}

export const kpiInitialValueCell = (
  data: Pick<KpiInterface, 'initial_value' | 'kpi_goal'>,
  textAlign: 'left' | 'right' = 'right',
) => {
  if (!data) {
    return null
  }
  if (isNil(data.initial_value)) {
    return '-'
  }
  const hasMaintainGoal = data.kpi_goal
    ? [KpiGoals.keep_target, KpiGoals.keep_target_up, KpiGoals.keep_target_down].includes(
        data.kpi_goal,
      )
    : false
  return hasMaintainGoal ? (
    <Tooltip placement="bottom" text="Lower boundary" justifyContent={textAlign}>
      <LongNumberCell value={data.initial_value} />
    </Tooltip>
  ) : (
    <LongNumberCell value={data.initial_value} textAlign={textAlign} />
  )
}

export const kpiInitialValueColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: 'initial_value',
  filterKey: 'initial_value',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => kpiInitialValueCell(data, kpiInitialValueColumn.textAlign),
  textAlign: 'left',
  title: 'Initial',
}

export const kpiCurrentValueCell = (
  value: number,
  textAlign: 'right' | 'left' = 'right',
) => {
  return !isNil(value) ? <LongNumberCell value={value} textAlign={textAlign} /> : '-'
}

export const kpiMetricColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: 'current_progress',
  filterKey: 'current_progress',
  filterType: FilterType.range,
  title: 'Metric',
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <GoalMetricCell
      initial={data.initial_value}
      target={data.target}
      current={data.current_progress}
      targetType={data.kpi_goal}
      progress={data.calibrated_progress || data.performance}
    />
  ),
}

export const kpiCurrentValueColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: 'current_progress',
  filterKey: 'current_progress',
  filterType: FilterType.range,

  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    kpiCurrentValueCell(data.current_progress, kpiCurrentValueColumn.textAlign),
  textAlign: 'left',
  title: 'Current',
}

export const kpiTargetCell = (
  data: Pick<KpiInterface, 'target' | 'kpi_goal'>,
  textAlign: 'left' | 'right' = 'right',
) => {
  if (!data) {
    return null
  }
  if (isNil(data.target)) {
    return '-'
  }
  const hasMaintainGoal = data.kpi_goal
    ? [KpiGoals.keep_target, KpiGoals.keep_target_up, KpiGoals.keep_target_down].includes(
        data.kpi_goal,
      )
    : false
  return hasMaintainGoal ? (
    <Tooltip placement="bottom" text="Upper boundary" justifyContent={textAlign}>
      <LongNumberCell value={data.target} />
    </Tooltip>
  ) : (
    <LongNumberCell value={data.target} textAlign={textAlign} />
  )
}

export const kpiTargetColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: 'target',
  filterKey: 'target',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => kpiTargetCell(data, kpiTargetColumn.textAlign),
  textAlign: 'left',
  title: 'Target',
}

export const kpiUnitColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.text,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Unit',
}

export const kpiStrategyColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Strategy',
  insert: ({ data }) => (data.kpi_goal ? strategyLabelsMap[data.kpi_goal] : '-'),
}

export const kpiCalibratedPerformanceColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'calibrated_progress',
  dataPoint: 'calibrated_progress',
  sortKey: 'calibrated_progress',
  filterKey: 'calibrated_progress',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  headerTooltip: <CalibratedTooltip />,
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }

    if (!data.calibrated_progress && !data.calibration_comment) {
      return '-'
    }

    return (
      <Flex width={75} justifyContent="space-between">
        <PercentageWrapper>
          <ColoredPercent percent={(data.calibrated_progress || 0) * 100} />
          {data.calibration_comment && (
            <Tooltip placement="top" text={data.calibration_comment}>
              <Box px="s-4">
                <InfoOutline size={14} />
              </Box>
            </Tooltip>
          )}
        </PercentageWrapper>
      </Flex>
    )
  },
  title: 'Calibrated Performance',
}

export const kpiPerformanceColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }

    return (
      <Table.ProgressCell
        value={
          isNumber(data.calibrated_progress) ? data.calibrated_progress : data.performance
        }
        strikeThroughValue={
          isNumber(data.calibrated_progress) ? data.performance * 100 : undefined
        }
        graph={{ fetchFunction: getKpiPerformanceGraph, id: data.id }}
        infoTooltip={data.calibration_comment}
      />
    )
  },
  title: 'Progress',
}

const getPerformance = (data: KpiInterface | GoalsInterface) =>
  'progress' in data ? data.progress : data.performance

export const getKpiGradeColumn: (
  evaluationCriteria: GradeEvaluationCriterion[],
) => ColumnInterface<KpiInterface | GoalsInterface> = evaluationCriteria => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }

    const performanceProgress = getPerformance(data)
    const evaluationCriterion = getGradeEvaluationCriterionByDeliverablesProgress(
      performanceProgress,
      evaluationCriteria,
    )

    if (!evaluationCriterion) {
      return 'n/a'
    }

    const deliverableRating = getDeliverableRatingByFinalGrade(
      evaluationCriterion.codename,
    )

    return (
      <Text color={getDeliverableOptionColors(deliverableRating).text}>
        {evaluationCriterion.label}
      </Text>
    )
  },
  background: data => {
    const performanceProgress = getPerformance(data)
    const evaluationCriterion = getGradeEvaluationCriterionByDeliverablesProgress(
      performanceProgress,
      evaluationCriteria,
    )

    if (!evaluationCriterion) {
      return 'inherit'
    }

    const deliverableRating = getDeliverableRatingByFinalGrade(
      evaluationCriterion.codename,
    )

    return getDeliverableOptionColors(deliverableRating).background
  },
  headerTooltip: (
    <Box p="s-12" pt={0} color={Token.color.background} minWidth={320}>
      <p>Grades are recommended based on goal progress:</p>
      <DeliverablesEvaluationCriteriaExplanationMarkdown criteria={evaluationCriteria} />
      <p>Different recommendations may apply for specific business areas.</p>
    </Box>
  ),
  title: 'Suggested grade',
})

export const kpiStatusColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target_status',
  dataPoint: 'target_status',
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }
    switch (data.target_status) {
      case Statuses.approved:
        return (
          <Tooltip text="Approved" placement="left">
            <ColoredText color={Token.color.green_60}>
              <Icon type="Check" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.pending:
        return (
          <Tooltip text="Pending" placement="left">
            <ColoredText color={Token.color.warning}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.archived:
        return (
          <Tooltip text="Archived" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Delete" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.rejected:
        return (
          <Tooltip text="Rejected" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Close" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.future:
        return (
          <Tooltip text="Future" placement="left">
            <ColoredText color={Token.color.lightBlue_60}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.requires_changes:
        return (
          <Tooltip text="Requires changes" placement="left">
            <ColoredText color={Token.color.warning}>
              <ArrowExchange size={16} />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.completed:
        return (
          <Tooltip text="Completed" placement="left">
            <ColoredText
              color={getPercentColor(
                (data.calibrated_progress || data.performance) * 100,
              )}
            >
              <CalendarCheck size={16} />
            </ColoredText>
          </Tooltip>
        )
      default:
        return data.target_status || ''
    }
  },
  sortKey: 'target_status',
  filterKey: 'target_status',
  selectorsKey: selectorKeys.kpi_targets_statuses,
  title: 'Status',
}

export const kpiStatusTextColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target_status',
  dataPoint: 'target_status',
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }

    let color: Color

    switch (data.target_status) {
      case Statuses.approved:
        color = Token.color.green
        break
      case Statuses.requires_changes:
      case Statuses.pending:
        color = Token.color.warning
        break
      case Statuses.archived:
        color = Token.color.greyTone50
        break
      case Statuses.rejected:
        color = Token.color.danger
        break
      case Statuses.future:
        color = Token.color.lightBlue_60
        break
      case Statuses.completed:
        color = Token.color.greyTone50
        break
      default:
        color = Token.color.foreground
        break
    }
    return <Text color={color}>{upperFirst(data.target_status).replace('_', ' ')}</Text>
  },
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.kpi_statuses,
  title: 'Status',
}

export const getKpiApprovalActionsColumn = (): ColumnInterface<KpiInterface> => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    if (
      data.target_status !== Statuses.pending &&
      data.target_status !== Statuses.requires_changes
    ) {
      return (
        <Text
          mr="10px"
          use="a"
          fontWeight={500}
          color={Token.color.lightBlue}
          onClick={e => {
            navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: data.id }), {
              backUrl: `${getLocationPathnameWithoutWorkspace()}${
                window.location.search
              }`,
            })
            e.stopPropagation()
          }}
        >
          Edit
        </Text>
      )
    }
    return null
  },
})

export const kpiParentColumn: ColumnInterface<KpiInterface | KPITemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'parent',
  dataPoint: 'parent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Parent',
  insert: ({ data }) => <ParentLink data={data} />,
}

export const kpiWeightColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  headerTooltip: (
    <TooltipContainer>
      The KPIs weight is taken in consideration when calculating the final performance.
      Mandatory KPIs have binary weights, which means failure to complete it in time will
      result in 0% performance for that quarter.
    </TooltipContainer>
  ),
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }
    if (
      Number(data.weight) > 100 ||
      data.kpi_performance_type?.id === KPIPerformanceTypes.mandatory
    ) {
      return <Text color="red">Mandatory</Text>
    }
    if (data.target_status === Statuses.rejected) {
      return <>-</>
    }
    return data.weight ? `${formatNumber(data.weight, 2)}%` : ''
  },
  title: 'Weight',
}

export const editableKpiWeightColumn: EditableColumnInterface<KpiInterface> = {
  inputType: TableCellInputType.positiveFloat,
  idPoint: 'weight',
  colors: data => (data.weight === 0 ? Token.color.danger : Token.color.foreground),
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  headerTooltip: (
    <TooltipContainer>
      The KPIs weight is taken in consideration when calculating the final performance.
      Mandatory KPIs have binary weights, which means failure to complete it in time will
      result in 0% performance for that quarter.
    </TooltipContainer>
  ),
  isHidden: data => data.id === -1,
  isEditable: data =>
    data.kpi_performance_type.id !== KPIPerformanceTypes.mandatory &&
    data.target_status !== Statuses.rejected &&
    !data.enforce_weight,
  notEditableInsert: data => {
    if (data.enforce_weight) {
      return (
        <Flex alignItems="center">
          <Text pr="s-6">{data.weight}</Text>
          <Tooltip
            placement="right"
            text="This weight is enforced by the KPI template and can not be changed"
          >
            <InfoOutline color="grey-tone-50" size={10} />
          </Tooltip>
        </Flex>
      )
    }
    if (data.kpi_performance_type.id === KPIPerformanceTypes.mandatory) {
      return <Text color="red">Mandatory</Text>
    }
    if (data.target_status === Statuses.rejected) {
      return <>-</>
    }
    return <></>
  },
  suffix: '%',
  title: 'Weight',
}

export const kpiParentOwnerColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'parent__owner.id',
  dataPoint: 'parent__owner.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Parent KPI Owner',
  insert: ({ data }) => <UserWithAvatar {...data.parent?.owner} />,
}

export const kpiOwnerColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const kpiRuleApplicationColumn: ColumnInterface<KPIRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'application_strategy.id',
  dataPoint: 'application_strategy.name',
  sortKey: 'application_strategy__name',
  filterKey: 'application_strategy__id',
  selectorsKey: selectorKeys.kpi_application_strategies,
  title: 'Apply to',
}

export const kpiRuleTemplateColumn: ColumnInterface<KPIRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'template.id',
  dataPoint: 'template.name',
  sortKey: 'template__name',
  filterKey: 'template__id',
  title: 'Template',
  selectorsKey: async () => {
    const options = await kpiRuleTemplateSelector()
    return { options }
  },
}

export const kpiRuleDescriptionColumn: ColumnInterface<KPIRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: 'description',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const kpiRuleNameColumn: ColumnInterface<KPIRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.kpi_template_rules,
  title: 'Rule Name',
}

export const kpiTypeColumn: ColumnInterface<KpiInterface | KPITemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'kpi_type.name',
  dataPoint: 'kpi_type.name',
  sortKey: 'kpi_type',
  filterKey: 'kpi_type',
  selectorsKey: selectorKeys.kpi_types,
  title: 'Type',
}

export const kpiPerformanceTypeColumn: ColumnInterface<
  KpiInterface | KPITemplateInterface
> = {
  type: CellTypes.insert,
  idPoint: 'kpi_performance_type.id',
  dataPoint: 'kpi_performance_type.name',
  sortKey: 'kpi_performance_type',
  filterKey: 'kpi_performance_type',
  selectorsKey: selectorKeys.kpi_performance_types,
  title: 'Type',
  insert: ({ data }) => {
    if (data.id === -1) {
      return null
    }
    return (
      <Box
        borderRadius={4}
        color="grey-tone-50"
        backgroundColor="grey-tone-5"
        py="s-2"
        px="s-8"
        width="min-content"
      >
        <Text fontWeight={500} fontSize="small">
          {data?.kpi_performance_type?.name || '-'}
        </Text>
      </Box>
    )
  },
}

export const kpiUpdateTypeColumn: ColumnInterface<KpiInterface | CompanyGoalInterface> = {
  type: CellTypes.insert,
  idPoint: 'update_type',
  dataPoint: 'update_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Update type',
  insert: ({ data }) => {
    if (data.id === -1 || !('update_type' in data)) {
      return null
    }
    let icon = null
    let tooltip

    switch (data.update_type) {
      case UpdateTypes.manual:
        tooltip = 'Manual'
        icon = <Profile color="grey-tone-50" size={18} />
        break
      case UpdateTypes.sql:
        tooltip = 'Automatic'
        icon = <ArrowRepeat color="grey-tone-50" size={16} />
        break
      case UpdateTypes.roadmap:
        tooltip = 'Roadmap'
        icon = (
          <Flex color="primary">
            <Icon type="Jira" size="tiny" />
          </Flex>
        )
        break
    }
    const templateIcon =
      'kpi_category' in data && data.kpi_category?.id === 'template' ? (
        <Tooltip placement="bottom" text="From a KPI template">
          <Flex color="primary">
            <Form color="grey-50" size={16} />
          </Flex>
        </Tooltip>
      ) : null

    return (
      <Flex gap="s-4">
        <Tooltip placement="left" text={tooltip}>
          {icon}
        </Tooltip>
        {templateIcon}
      </Flex>
    )
  },
}

export const kpiTemplateNameColumn: ColumnInterface<KPITemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.kpi_templates,
  title: 'Template Name',
}

export const kpiTemplateEnforcedColumn: ColumnInterface<KPITemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'targets_strategy',
  dataPoint: 'targets_strategy',
  sortKey: 'targets_strategy',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <KpiEnforce data={data} />,
  title: 'Enforced',
}

export const kpiTemplateCountColumn: ColumnInterface<KPITemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'implemented_kpis',
  dataPoint: 'implemented_kpis',
  sortKey: 'implemented_kpis',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Implemented kpis',
}

export const kpiRatingColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: 'rating',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.rating ? (
      <PerformanceRatingLabelTag
        rating={data.rating}
        fontSize="caption"
        fontWeight={400}
      />
    ) : (
      '-'
    ),
  title: 'Rating',
}

export const kpiDashboardsColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Dashboards',
}

export const kpiRelatedDashboardsNameColumn: ColumnInterface<KpiRelatedDashboardsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    dynamicHyperlinks: data =>
      pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, { id: data.id }),
    title: 'Name',
  }

export const kpiRelatedDashboardsActionsColumn: ColumnInterface<KpiRelatedDashboardsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
    insert: ({ data }) => {
      return (
        <TextButton
          onClick={e => {
            e.stopPropagation()

            navigateTo(
              pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, {
                id: data.id,
              }),
            )
          }}
        >
          View
        </TextButton>
      )
    },
  }
