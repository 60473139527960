import { AxiosPromise } from 'axios'
import { useQueryClient } from 'react-query'

import { apiV2 } from '@src/api/index'
import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'
import { API, selectorKeys } from '@src/constants/api'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import {
  DriverResultStatsInterface,
  EmployeeWithSurveyAccessInterface,
  EngagementAnswerInterface,
  EngagementAnswerReplyInterface,
  EngagementDriverInterface,
  EngagementHeatmapExportInterface,
  EngagementInsightsInterface,
  EngagementInsightsStatusInterface,
  EngagementInsightsTarget,
  EngagementOutlierInterface,
  EngagementQuestionInterface,
  EngagementQuestionVoicesInterface,
  EngagementResultInterface,
  EngagementResultsComment,
  EngagementResultsItemTimeSeriesInterface,
  EngagementResultsUserAccessModeInterface,
  EngagementStartSurveyInterface,
  EngagementSurveyDetailsInterface,
  EngagementSurveyInterface,
  EngagementSurveyRoundInterface,
  EngagementSurveySettingsInterface,
  EngagementSurveyStatsInterface,
  GroupByOption,
} from '@src/interfaces/engagement'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { DetailsPageType } from '@src/pages/Performance/Engagement/components/SurveyResults/ResultsWidget/common'
import { FeedbackSubmitInterface } from '@src/store/feedback/types'
import { saveFile } from '@src/utils'
import {
  useFetchV2,
  usePostV2,
  useUpdateV2,
  UseQueryOptions,
} from '@src/utils/reactQuery'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { toIdAndName } from '@src/utils/toIdAndName'
import { ApiQueryParams } from './types'

export const getQuestionsQueue = (surveyId: string, isTestSurvey?: boolean) => {
  return apiV2.get<EngagementQuestionVoicesInterface[]>(
    `${API.ENGAGEMENT_QUESTIONS}/queue`,
    {
      params: {
        survey_id: surveyId,
        is_test: isTestSurvey ? true : undefined,
      },
    },
  )
}

export const engagementQuestionBoxFormRequests: RequestInterfaceNew<EngagementAnswerInterface> =
  {
    get: async () => apiV2.get(API.ENGAGEMENT_ANSWERS),
    update: async data => apiV2.patch(API.ENGAGEMENT_ANSWERS, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_ANSWERS, data),
  }

export const didAnswerOnboardingQuestion = () => {
  return apiV2.get<boolean>(
    `${API.USER}/onboardingFeedback/did_answer_onboarding_question`,
    undefined,
    undefined,
    true,
  )
}

export const submitOnboardingAnswer = (answer: FeedbackSubmitInterface) =>
  apiV2.post(`${API.USER}/onboardingFeedback`, answer, undefined, undefined, true)

export const engagementSurveySettingsRequests: RequestInterfaceNew<EngagementSurveySettingsInterface> =
  {
    get: async () => apiV2.get(API.ENGAGEMENT_SURVEY_SETTINGS),
    update: async data => apiV2.patch(API.ENGAGEMENT_SURVEY_SETTINGS, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_SURVEY_SETTINGS, data),
  }

export const getEngagementAudiences = (params: ApiQueryParams) =>
  apiV2.get<GetRequestData<AudienceInterface>>(API.ENGAGEMENT_AUDIENCES, {
    params,
  })

export const useEngagementAudiencesSelector = (params: ApiQueryParams) => {
  return useFetchV2<{ options: AudienceInterface[] }>({
    url: `${API.ENGAGEMENT_AUDIENCES}/selector`,
    params: { params },
  })
}

export const engagementDriversTableRequests: TableRequestInterface<EngagementDriverInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(API.ENGAGEMENT_DRIVERS, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const engagementDriverFormRequests: RequestInterfaceNew<EngagementDriverInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.ENGAGEMENT_DRIVERS}/${id}`),
    update: async (data, { id }) => apiV2.patch(`${API.ENGAGEMENT_DRIVERS}/${id}`, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_DRIVERS, data),
  }

export const archiveEngagementDriver = (
  id: number,
): AxiosPromise<EngagementDriverInterface> =>
  apiV2.post(`${API.ENGAGEMENT_DRIVERS}/${id}/archive`)

export const unarchiveEngagementDriver = (
  id: number,
): AxiosPromise<EngagementDriverInterface> =>
  apiV2.post(`${API.ENGAGEMENT_DRIVERS}/${id}/unarchive`)

export const engagementQuestionsTableRequests: TableRequestInterface<EngagementQuestionInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(API.ENGAGEMENT_QUESTIONS, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const engagementQuestionFormRequests: RequestInterfaceNew<EngagementQuestionInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.ENGAGEMENT_QUESTIONS}/${id}`),
    update: async (data, { id }) =>
      apiV2.patch(`${API.ENGAGEMENT_QUESTIONS}/${id}`, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_QUESTIONS, data),
  }

export const archiveEngagementQuestion = (
  id: number,
): AxiosPromise<EngagementQuestionInterface> =>
  apiV2.post(`${API.ENGAGEMENT_QUESTIONS}/${id}/archive`)

export const unarchiveEngagementQuestion = (
  id: number,
): AxiosPromise<EngagementQuestionInterface> =>
  apiV2.post(`${API.ENGAGEMENT_QUESTIONS}/${id}/unarchive`)

export const engagementSurveysTableRequests: TableRequestInterface<
  EngagementSurveyInterface,
  EngagementSurveyStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(API.ENGAGEMENT_SURVEYS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    apiV2.get(`${API.ENGAGEMENT_SURVEYS}/stats`, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
}

export const useGetSurveysStats = () =>
  useFetchV2<EngagementSurveyStatsInterface>({
    url: `${API.ENGAGEMENT_SURVEYS}/stats`,
    params: {
      params: filterSortPageIntoQuery(undefined, [
        {
          columnName: 'round_status',
          filters: [
            toIdAndName(Statuses.active),
            toIdAndName(Statuses.inactive),
            toIdAndName(Statuses.running),
            toIdAndName(Statuses.planned),
          ],
        },
        {
          columnName: 'status',
          filters: [
            toIdAndName(Statuses.active),
            toIdAndName(Statuses.inactive),
            toIdAndName(Statuses.scheduled),
            toIdAndName(Statuses.completed),
            toIdAndName(Statuses.archived),
            toIdAndName(Statuses.terminated),
          ],
        },
      ]),
    },
  })

export const useRefetchEngagementSurveyRounds = (surveyId?: number | string) => {
  const queryClient = useQueryClient()

  const refetchEngagementSurveyRounds = () => {
    if (surveyId) {
      queryClient.refetchQueries(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds`)
    }
  }

  return { refetchEngagementSurveyRounds }
}

export const useGetEngagementSurveyRounds = (
  surveyId: number | string | undefined,
  params?: ApiQueryParams,
) =>
  useFetchV2<GetRequestInterface<EngagementSurveyRoundInterface>>({
    url: `${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds`,
    params: { params },
    queryOptions: { enabled: surveyId != null },
  })

export const getEngagementRoundFormRequest = (
  surveyId: string | number,
  roundId: string | number,
): RequestInterfaceNew<EngagementSurveyRoundInterface> => ({
  get: async () => apiV2.get(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`),
  update: data =>
    apiV2.patch(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`, data),
  submit: data =>
    apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`, data),
})

export const engagementSurveyFormRequests: RequestInterfaceNew<EngagementSurveyInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.ENGAGEMENT_SURVEYS}/${id}`),
    update: async (data, { id }) => apiV2.patch(`${API.ENGAGEMENT_SURVEYS}/${id}`, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_SURVEYS, data),
  }

export const getEngagementQuestionsOptions = ({ params }: { params?: ApiQueryParams }) =>
  apiV2.get<GetRequestData<EngagementQuestionInterface>>(`${API.ENGAGEMENT_QUESTIONS}`, {
    params,
  })

export const useEngagementSurveysTemplates = () =>
  useFetchV2<GetRequestInterface<EngagementSurveyInterface>>({
    url: API.ENGAGEMENT_SURVEYS,
    params: {
      params: filterSortPageIntoQuery(undefined, [
        { columnName: 'status', filters: [toIdAndName('template')] },
      ]),
    },
  })

export const useGetEngagementSurvey = (id?: number | string) =>
  useFetchV2<EngagementSurveyInterface>({
    url: `${API.ENGAGEMENT_SURVEYS}/${id}`,
    queryOptions: { enabled: id != null },
  })

export const useUpdateEngagementSurvey = () =>
  useUpdateV2<EngagementSurveyInterface, EngagementSurveyInterface>({
    url: API.ENGAGEMENT_SURVEYS,
    usePut: false,
    updater: (_oldData, newData) => newData,
  })

export const cancelEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/cancel`)

export const activateEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/activate`)

export const archiveEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/archive`)

export const unarchiveEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/unarchive`)

export const sendEngagementSurvey = (
  id: number | string,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/send`)

export const closeSurveyRound = (
  id: number,
  roundId: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds/${roundId}/close`)

export const cancelSurveyRound = (
  id: number,
  roundId: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds/${roundId}/cancel`)

export const testEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/test`)

export const createSurveyFromTemplate = (
  id: number,
  data?: EngagementSurveyInterface,
): AxiosPromise<EngagementSurveyInterface> =>
  apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${id}/createFromTemplate`, data)

export const useEngagementResultsQuestions = ({
  enabled = true,
  params,
}: {
  enabled?: boolean
  params?: ApiQueryParams
}) =>
  useFetchV2<GetRequestInterface<EngagementQuestionInterface>>({
    url: API.ENGAGEMENT_QUESTIONS,
    params: { params },
    queryOptions: { enabled },
  })

export const refreshEngagementSurveyStatistics = ({
  surveyId,
}: {
  surveyId: number | string
}) => apiV2.post(`/engagementSurveys/${surveyId}/refreshStatistics`)

export const useEngagementResultsDrivers = ({
  params,
  enabled = true,
}: {
  enabled?: boolean
  params?: ApiQueryParams
}) =>
  useFetchV2<GetRequestInterface<EngagementDriverInterface>>({
    url: API.ENGAGEMENT_DRIVERS,
    params: { params },
    queryOptions: { enabled },
  })

export const getSurveyAdminResultCommentsTableRequests =
  (): TableRequestInterface<EngagementResultsComment> => ({
    getItems: async ({ sortBy, filters = [], page }) =>
      apiV2.get(`/company/1/all/engagementComments`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  })

export const useAdminEngagementResultStats = (
  surveyId?: number,
  roundId?: string | number,
) =>
  useFetchV2<DriverResultStatsInterface>({
    url: `/company/1/culture/${surveyId}/all/drivers/stats`,
    version: 'v2',
    params: { params: { survey_round_id: roundId } },
    queryOptions: { enabled: roundId != null && surveyId != null },
  })

export const getSurveyAdminResultQuestionsTableRequests = ({
  surveyId,
}: {
  surveyId: number
}): TableRequestInterface<EngagementResultInterface> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    apiV2.get(
      `/company/1/culture/${surveyId}/all/questions`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const getSurveyAdminResultDriversTableRequests = ({
  surveyId,
}: {
  surveyId: number
}): TableRequestInterface<EngagementResultInterface> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    apiV2.get(
      `/company/1/culture/${surveyId}/all/drivers`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const useEngagementWelcomePage = ({
  surveyId,
  isTestSurvey,
}: {
  surveyId?: string
  isTestSurvey?: boolean
}) =>
  useFetchV2<EngagementSurveyDetailsInterface>({
    url: `${API.ENGAGEMENT_SURVEYS}/${surveyId}/get_welcome_page`,
    params: isTestSurvey ? { is_test: true } : {},
    queryOptions: { enabled: surveyId != null, refetchOnWindowFocus: false },
  })

export const useEngagementNotifications = (enabled: boolean) =>
  useFetchV2<GetRequestInterface<NotificationsInterface>>({
    url: `${API.NOTIFICATIONS}/engagement`,
    version: 'v2',
    params: { enabled },
  })

export const engagementSurveyHistoryRequests = (
  id: string | number,
): TableRequestInterface<EngagementSurveyRoundInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getStartSurveyFormRequests = (
  surveyId: number,
): RequestInterfaceNew<EngagementStartSurveyInterface> => ({
  get: async () => apiV2.get(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`),
  update: async data => apiV2.patch(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`, data),
  submit: async data => {
    return apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`, data)
  },
})

export const useGetGroupByOptionItems = (
  surveyId: number | undefined,
  groupBy?: string,
  scopeFilters?: FilterByInterface[],
) =>
  useFetchV2<{ options: GroupByOption[] }>({
    url: API.SELECTORS,
    params: {
      params: {
        name: selectorKeys.engagement_analytics_heatmap_groups_options,
        survey_id: surveyId,
        group_by: groupBy,
        ...groupFiltersIntoURLQuery(scopeFilters),
      },
    },
    queryOptions: {
      enabled: Boolean(groupBy && surveyId),
    },
  })

export const useGetSurveyResultsItemTimeSeries = ({
  surveyId,
  itemsToAnalyse,
  itemId,
  groupBy = 'run',
  filtersParams,
}: {
  surveyId: number | string
  itemsToAnalyse?: DetailsPageType
  itemId?: number | string
  groupBy?: 'run' | 'week' | 'month'
  filtersParams?: ApiQueryParams
}) =>
  useFetchV2<EngagementResultsItemTimeSeriesInterface[]>({
    url: `/engagement/analytics/survey/${surveyId}/${
      itemsToAnalyse === 'question' ? 'questions' : 'drivers'
    }/${itemId}/timeSeries`,
    params: {
      params: {
        group_by: groupBy,
        ...filtersParams,
      },
    },
    queryOptions: {
      enabled: Boolean(itemsToAnalyse && itemId),
    },
  })

export const useGetSurveyOverviewTimeSeries = ({
  surveyId,
  groupBy = 'run',
}: {
  surveyId: number | string | undefined
  groupBy?: 'run' | 'week' | 'month'
}) =>
  useFetchV2<EngagementResultsItemTimeSeriesInterface[]>({
    url: `/engagement/analytics/survey/${surveyId}/timeSeries`,
    params: {
      params: {
        group_by: groupBy,
      },
    },
    queryOptions: {
      enabled: surveyId != null,
    },
  })

export const engagementAnswersTableRequests: TableRequestInterface<EngagementAnswerInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get('/engagementAnswers', {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useGetEngagementAnswerDetails = (answerId: number) =>
  useFetchV2<EngagementAnswerInterface>({
    url: `/engagementAnswers/${answerId}`,
  })

export const useUpdateEngagementAnswerDetails = () =>
  useUpdateV2<EngagementAnswerInterface, Partial<EngagementAnswerInterface>>({
    url: `/engagementAnswers`,
  })

export const acknowledgeEngagementAnswer = (id: number) =>
  apiV2.post(`/engagementAnswers/${id}/acknowledge`)

export const useGetEngagementAnswerReplies = (answerId: string | number) =>
  useFetchV2<GetRequestInterface<EngagementAnswerReplyInterface>>({
    url: `/engagementAnswers/${answerId}/replies`,
  })

export const useCreateEngagementAnswerReply = (answerId: string | number) =>
  usePostV2({
    url: `/engagementAnswers/${answerId}/replies`,
  })

export const createHeatmapExport = (
  surveyId: number,
  dateFrom?: string,
  dateTo?: string,
) =>
  apiV2.post(
    `/engagement/analytics/survey/${surveyId}/exports`,
    {},
    {
      params: { from_date: dateFrom, to_date: dateTo },
    },
  )

export const getHeatmapExports = (surveyId: number, params?: ApiQueryParams) =>
  apiV2.get<GetRequestInterface<EngagementHeatmapExportInterface>>(
    `/engagement/analytics/survey/${surveyId}/exports`,
    { params },
  )

export const downloadHeatmapExportFile = async (surveyId: number, exportId: number) => {
  const fileInfoRes = await apiV2.get<{ url: string }>(
    `/engagement/analytics/survey/${surveyId}/exports/${exportId}/download`,
  )
  saveFile(fileInfoRes.data.url, 'heatmap.xlsx')
}

export const useGetSurveyResultsAccessModes = (surveyId: number | null | undefined) =>
  useFetchV2<GetRequestInterface<EngagementResultsUserAccessModeInterface>>({
    url: `/engagement/survey/${surveyId}/access`,
    queryOptions: { enabled: surveyId != null },
  })

export const createSurveyResultsUserAccessMode = (
  surveyId: number,
  userId: number,
  accessType: 'can_view' | 'can_edit',
) =>
  apiV2.post<EngagementResultsUserAccessModeInterface>(
    `/engagement/survey/${surveyId}/access`,
    {
      survey: { id: surveyId },
      employee: { id: userId },
      access_type: { id: accessType },
    },
  )

export const updateSurveyResultsUserAccessMode = (
  accessModeId: number,
  surveyId: number,
  userId: number,
  accessType: 'can_view' | 'can_edit',
) =>
  apiV2.patch<EngagementResultsUserAccessModeInterface>(
    `/engagement/survey/${surveyId}/access/${accessModeId}`,
    {
      survey: { id: surveyId },
      employee: { id: userId },
      access_type: { id: accessType },
    },
  )

export const createGetEmployeesWithSurveyResultsAccess =
  (surveyId: number) =>
  ({ params }: { params: ApiQueryParams }) =>
    apiV2.get<GetRequestInterface<EmployeeWithSurveyAccessInterface>>(
      `/engagement/survey/${surveyId}/access/employees`,
      { params },
    )

export const useGetEngagementInsights = ({
  roundId,
  insightType,
  itemId,
  params,
  queryOptions,
}: {
  roundId: number
  insightType: EngagementInsightsTarget
  itemId: number
  params?: ApiQueryParams<ScopeFilterKey>
  queryOptions?: UseQueryOptions<EngagementInsightsInterface[]>
}) =>
  useFetchV2<EngagementInsightsInterface[]>({
    url: `/engagement/analytics/round/${roundId}/${insightType}/${itemId}/insights`,
    params: {
      params,
    },
    queryOptions,
  })

export const useGetEngagementInsightsStatus = ({
  roundId,
  params,
  queryOptions,
}: {
  roundId: number
  params?: ApiQueryParams<ScopeFilterKey>
  queryOptions?: UseQueryOptions<EngagementInsightsStatusInterface>
}) =>
  useFetchV2<EngagementInsightsStatusInterface>({
    url: `/engagement/analytics/insights/${roundId}/requests`,
    params: {
      params,
    },
    queryOptions,
  })

export const useGenerateInsights = ({ roundId }: { roundId: number }) =>
  usePostV2<
    EngagementInsightsStatusInterface,
    ApiQueryParams<ScopeFilterKey> | undefined
  >({
    url: `/engagement/analytics/insights/${roundId}/requests`,
  })

export const useGetEngagementOutliers = ({
  roundId,
  params,
  queryOptions,
}: {
  roundId: number
  params?: ApiQueryParams
  queryOptions?: UseQueryOptions<GetRequestInterface<EngagementOutlierInterface>>
}) =>
  useFetchV2<GetRequestInterface<EngagementOutlierInterface>>({
    url: `/engagement/analytics/insights/${roundId}/outliers`,
    params: {
      params,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      ...queryOptions,
    },
  })

export const getEngagementOutliers = ({
  params,
  roundId,
}: {
  roundId: number
  params: FetchDataQueryInterface
}) =>
  apiV2.get<GetRequestInterface<EngagementOutlierInterface>>(
    `/engagement/analytics/insights/${roundId}/outliers`,
    {
      params: filterSortPageIntoQuery(params.sortBy, params.filters, params.page),
    },
  )
