import React, { useState } from 'react'
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Token,
  Tooltip,
  useTooltip,
  Widget,
  Text,
  TabBar,
} from '@revolut/ui-kit'
import { EngagementInsightsTarget } from '@src/interfaces/engagement'
import { DatesRange } from '../../Rounds/Selector/types'
import { getInsightsWidgetStatus } from './helpers'
import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import { InsightsWidgetContent } from './InsightsWidgetContent'
import { useCheckOrGenerateInsights } from './hooks/useCheckOrGenerateInsights'
import { useGetInsightsWithScore } from './hooks/useGetInsightsWithScore'
import { OutliersContent } from './OutliersContent'

type InsightsWidgetTabs = 'insights' | 'trends' | 'outliers'
interface Props {
  surveyRoundId: number
  surveyId: number
  insightType: EngagementInsightsTarget
  categoryId?: number
  datesRange?: DatesRange
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}

export const InsightsWidget = ({
  surveyRoundId,
  insightType,
  categoryId,
  surveyId,
  datesRange,
  scopeParams,
}: Props) => {
  const [tab, setTab] = useState<InsightsWidgetTabs>('insights')
  const { insightsStatus, error: generateInsightsError } = useCheckOrGenerateInsights({
    roundId: surveyRoundId,
    scopeParams,
  })

  const {
    insights,
    isLoading: isInsightsLoading,
    insightsError,
  } = useGetInsightsWithScore({
    roundId: surveyRoundId,
    insightType,
    itemId: insightType === 'driver' && categoryId ? categoryId : surveyId,
    surveyId,
    datesRange,
    insightsGenerated: insightsStatus === 'success',
    scopeParams,
  })
  const tooltip = useTooltip()

  const commonInsightsStatus = getInsightsWidgetStatus({
    status: insightsStatus,
    haveInsights: !!insights?.length,
    error: !!insightsError || !!generateInsightsError,
    isInsightsLoading,
  })

  const showOutliers = !scopeParams || !!scopeParams.department || !!scopeParams.function

  return (
    <Widget p="s-20">
      {insightType === 'survey' ? (
        <HStack align="center">
          <TabBar<'div', InsightsWidgetTabs>
            variant="segmented fit"
            value={tab}
            onChange={value => value && setTab(value)}
          >
            <TabBar.Item to="insights" useIcon="LikeDislike">
              Strengths and Weaknesses
            </TabBar.Item>
            <TabBar.Item to="trends" useIcon="ArrowRates">
              Trends
            </TabBar.Item>
            {showOutliers && (
              <TabBar.Item to="outliers" useIcon="ArrowTopDown">
                Outliers
              </TabBar.Item>
            )}
          </TabBar>
          <Spacer />
          <HStack
            align="center"
            gap="s-6"
            color={Token.color.greyTone50}
            style={{ cursor: 'help' }}
            {...tooltip.getAnchorProps()}
          >
            <Icon name="Cleaning" size={20} color={Token.color.greyTone50} />
            <Text variant="caption">How we measure</Text>
            <Icon name="InfoOutline" size={16} color={Token.color.greyTone50} />
          </HStack>
        </HStack>
      ) : (
        <HStack align="center">
          <HStack
            align="center"
            gap="s-6"
            style={{ cursor: 'help' }}
            {...tooltip.getAnchorProps()}
          >
            <Icon name="Cleaning" size={22} />
            <Text>Insights</Text>
            <Icon name="InfoOutline" size={16} color={Token.color.greyTone50} />
          </HStack>
        </HStack>
      )}
      <Tooltip {...tooltip.getTargetProps()}>
        We use open comments and question responses to generate these insights
      </Tooltip>
      <Box mt="s-20">
        {tab === 'outliers' ? (
          <OutliersContent surveyRoundId={surveyRoundId} />
        ) : (
          <InsightsWidgetContent
            insights={insights}
            insightType={insightType}
            insightsStatus={commonInsightsStatus}
            surveyRoundId={surveyRoundId}
            surveyId={surveyId}
            scopeParams={scopeParams}
            scoreVariant={tab === 'trends' ? 'trend' : 'average'}
            categories={
              tab === 'trends'
                ? ['upward_trend', 'downward_trend']
                : ['strength', 'area_of_development']
            }
          />
        )}
      </Box>
    </Widget>
  )
}
