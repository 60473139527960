import { FilterButtonSkeleton, Flex, Placeholder, VStack } from '@revolut/ui-kit'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { useGetEngagementSurvey } from '@src/api/engagement'
import Table from '@src/components/TableV2/Table'
import TableLoader from '@src/components/TableV2/TableLoader'
import { NoSurveysPlaceholder } from '@src/features/Engagement/components/NoSurveysPlaceholder'
import TabBarNavigation, {
  TabBarTableNavigationProps,
} from '@src/features/TabBarNavigation/TabBarNavigation'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FilterByInterface } from '@src/interfaces/data'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import { InsightsWidget } from '@src/pages/Performance/Engagement/components/SurveyResults/InsightsWidget'
import { FeatureFlags } from '@src/store/auth/types'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { useSubtabs } from '../../hooks/useSubtabs'
import {
  useSurveyRoundSelector,
  UseSurveyRoundSelectorReturnType,
} from '../Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimelineFilterInterface } from '../Rounds/Selector/types'
import { SurveySubheader } from '../Subheader'
import { ResultsWidget } from './ResultsWidget'
import {
  useSurveySelector,
  UseSurveySelectorReturnType,
} from './ResultsWidget/SurverySelector'
import { useTimelineFilter } from './ResultsWidget/TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './ResultsWidget/hooks/useHeatmapFilters'

const WithSubheader = ({
  surveyId,
  subheaderRoundData,
  scopeFilters,
  timelineFilter,
  subtabs,
  children,
}: Pick<Props, 'subheaderRoundData' | 'scopeFilters'> & {
  surveyId: number | undefined
  timelineFilter: TimelineFilterInterface
  subtabs: TabBarTableNavigationProps['tabs']
  children: React.ReactNode
}) => {
  return (
    <VStack space="s-24">
      {subheaderRoundData && (
        <>
          <SurveySubheader
            isNestedRoundSelector
            roundSelectorData={subheaderRoundData}
            scopeFilters={scopeFilters}
          />
          {surveyId && subheaderRoundData.round.value?.id && (
            <InsightsWidget
              surveyId={surveyId}
              insightType="survey"
              surveyRoundId={subheaderRoundData.round.value?.id}
              datesRange={{
                dateFrom: timelineFilter.dateFrom,
                dateTo: timelineFilter.dateTo,
              }}
              scopeParams={groupFiltersIntoURLQuery(scopeFilters)}
            />
          )}
          <TabBarNavigation tabs={subtabs} />
        </>
      )}
      {children}
    </VStack>
  )
}

const TablePlaceholder = ({
  isLoading,
  isCompany,
  isEngagementDev,
}: {
  isEngagementDev: boolean
  isLoading?: boolean
  isCompany?: boolean
}) => {
  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          {isCompany ? (
            <CompanyNavigation />
          ) : !isEngagementDev && isLoading ? (
            <FilterButtonSkeleton width={250} />
          ) : null}
          {isLoading && <FilterButtonSkeleton width={150} />}
        </Flex>
      </Table.Widget.Info>
      {isLoading && (
        <Table.Widget.Filters>
          <FilterButtonSkeleton width={150} />
        </Table.Widget.Filters>
      )}
      <Table.Widget.Table>
        {isLoading ? <TableLoader loading rowHeight="large" /> : <NoSurveysPlaceholder />}
      </Table.Widget.Table>
    </Table.Widget>
  )
}

interface Props {
  entityId: number | undefined
  entityPermissions: string[] | undefined
  isCompany?: boolean
  scopeFilters: FilterByInterface[] | undefined
  invalidParamsWarning?: string
  subheaderRoundData?: UseSurveyRoundSelectorReturnType
  timelineFilter?: TimelineFilterInterface
  surveySelector?: UseSurveySelectorReturnType
}

const SurveyResultsPublishedInner = ({
  entityId,
  entityPermissions,
  scopeFilters,
  invalidParamsWarning,
  isCompany,
  subheaderRoundData,
  surveySelector,
  timelineFilter: timelineFilterFromProps,
}: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)
  const isNewLayout = isEngagementDev && !isCompany

  const surveyId = surveySelector?.value?.id || subheaderRoundData?.survey.value?.id

  const {
    data: survey,
    isLoading: isLoadingSurvey,
    refetch: refetchSurvey,
  } = useGetEngagementSurvey(surveyId)

  const timelineFilter = (subheaderRoundData ||
    timelineFilterFromProps) as TimelineFilterInterface
  const heatmapFilters = useHeatmapFilters(surveyId, scopeFilters)
  const subtabs = useSubtabs(entityId, entityPermissions, true)

  if (invalidParamsWarning) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not get surveys data</Placeholder.Title>
        <Placeholder.Description>{invalidParamsWarning}</Placeholder.Description>
      </Placeholder>
    )
  }

  const subheaderProps = {
    surveyId,
    subheaderRoundData,
    scopeFilters,
    timelineFilter,
    subtabs,
  }

  if (isLoadingSurvey) {
    return (
      <WithSubheader {...subheaderProps}>
        <TablePlaceholder
          isLoading
          isCompany={isCompany}
          isEngagementDev={isEngagementDev}
        />
      </WithSubheader>
    )
  }
  if (survey) {
    return (
      <WithSubheader {...subheaderProps}>
        {isNewLayout ? (
          <Switch>
            {subtabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component
                  survey={survey}
                  refetchSurvey={refetchSurvey}
                  timelineFilter={timelineFilter}
                  heatmapFilters={heatmapFilters}
                  scopeFilters={scopeFilters}
                />
              </Route>
            ))}
          </Switch>
        ) : (
          <ResultsWidget
            survey={survey}
            itemsToAnalyse={isEngagementDev ? 'categories' : undefined}
            refetchSurvey={refetchSurvey}
            timelineFilter={timelineFilter}
            heatmapFilters={heatmapFilters}
            scopeFilters={scopeFilters}
            surveySelector={surveySelector}
            isCompany={isCompany}
          />
        )}
      </WithSubheader>
    )
  }
  return (
    <WithSubheader {...subheaderProps}>
      <TablePlaceholder isCompany={isCompany} isEngagementDev={isEngagementDev} />
    </WithSubheader>
  )
}

const SurveyResultsPublishedWithoutSubheader = (props: Props) => {
  const surveySelector = useSurveySelector()
  const timelineFilter = useTimelineFilter(surveySelector.value?.id)

  return (
    <SurveyResultsPublishedInner
      {...props}
      surveySelector={surveySelector}
      timelineFilter={timelineFilter}
    />
  )
}

const SurveyResultsPublishedWithSubheader = (props: Props) => {
  const subheaderRoundData = useSurveyRoundSelector()
  return (
    <SurveyResultsPublishedInner {...props} subheaderRoundData={subheaderRoundData} />
  )
}

export const SurveyResultsPublished = (props: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)

  if (isEngagementDev && !props.isCompany) {
    return <SurveyResultsPublishedWithSubheader {...props} />
  }
  return <SurveyResultsPublishedWithoutSubheader {...props} />
}
