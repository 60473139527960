import { getLink, InsightScoreVariant, InsightsWidgetStatus } from './helpers'
import { Flex } from '@revolut/ui-kit'
import React, { useState } from 'react'
import {
  EngagementInsightCategory,
  EngagementInsightsTarget,
} from '@src/interfaces/engagement'
import { groupBy } from 'lodash'
import { InsightWithScore } from './hooks/useGetInsightsWithScore'
import { navigateTo } from '@src/actions/RouterActions'
import { InsightDetailsSideBar } from './InsightDetailsSideBar'
import { InsightsPlaceholder } from './components/InsightsPlaceholder'
import { InsightsCategory } from './components/InsightsCategory'
import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'

interface Props {
  insightsStatus: InsightsWidgetStatus
  insights: InsightWithScore[]
  insightType: EngagementInsightsTarget
  surveyId: number
  surveyRoundId: number
  categories: EngagementInsightCategory[]
  scoreVariant: InsightScoreVariant
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}

export const InsightsWidgetContent = ({
  insightsStatus,
  insights,
  insightType,
  surveyId,
  surveyRoundId,
  categories,
  scoreVariant,
  scopeParams,
}: Props) => {
  const [selectedInsight, setSelectedInsight] = useState<InsightWithScore>()
  const groupedInsights = groupBy(insights || [], insight => insight.category.id)
  const shouldRedirect = insightType === 'survey'
  const onInsightClick = (insight: InsightWithScore) => {
    if (shouldRedirect) {
      navigateTo(getLink({ insight, surveyId, surveyRoundId, scopeParams }))
      return
    }
    setSelectedInsight(insight)
  }

  if (insightsStatus !== 'completed') {
    return <InsightsPlaceholder status={insightsStatus} />
  }

  return (
    <Flex
      gap="s-16"
      alignItems={{ all: 'center', lg: 'stretch' }}
      flexDirection={{ all: 'column', lg: 'row' }}
    >
      {categories.map((category: EngagementInsightCategory) => (
        <InsightsCategory
          key={category}
          category={category}
          insights={groupedInsights[category]}
          onInsightClick={onInsightClick}
          scoreVariant={scoreVariant}
          insightType={insightType}
        />
      ))}
      {!!selectedInsight && (
        <InsightDetailsSideBar
          insight={selectedInsight}
          onClose={() => setSelectedInsight(undefined)}
        />
      )}
    </Flex>
  )
}
