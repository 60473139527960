import { Flex, Icon, Placeholder, TextSkeleton, Token, VStack } from '@revolut/ui-kit'
import React from 'react'
import { InsightsWidgetStatus } from '../helpers'

export const InsightsPlaceholder = ({ status }: { status: InsightsWidgetStatus }) => {
  const getStatusText = (): { title: string; subtitle: string } => {
    switch (status) {
      case 'generating':
        return {
          title: 'We are generating your insights',
          subtitle: 'This may take a few minutes. Feel free to check back later!',
        }
      case 'error':
        return {
          title: 'Unable to generate insights',
          subtitle: 'We had some issue generating our insights, please try again soon.',
        }
      case 'no_insights':
      default:
        return {
          title: 'Not enough results',
          subtitle: 'There isn’t enough data to generate insights for this survey',
        }
    }
  }

  const getStatusIndicator = () => {
    switch (status) {
      case 'generating':
        return (
          <VStack width="240px" height="60px" align="center">
            <TextSkeleton width="100%" variant="caption" />
            <TextSkeleton width="100%" variant="caption" />
            <TextSkeleton width="100%" variant="caption" />
          </VStack>
        )
      case 'error':
        return (
          <Flex height="60px" alignItems="center">
            <Icon name="CrossCircle" size={52} color={Token.color.greyTone50} />
          </Flex>
        )
      case 'no_insights':
      default:
        return (
          <Flex height="60px" alignItems="center">
            <Icon name="BarChartSearch" size={52} color={Token.color.greyTone50} />
          </Flex>
        )
    }
  }

  return (
    <Placeholder>
      {getStatusIndicator()}
      <Placeholder.Title>{getStatusText().title}</Placeholder.Title>
      <Placeholder.Description>{getStatusText().subtitle}</Placeholder.Description>
    </Placeholder>
  )
}
