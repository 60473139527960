import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { TeamGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsCardTable'
import { EmployeeGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmployeeKpisCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeKpisCardTable'
import { ActionButton, HStack, Icon, Token, VStack } from '@revolut/ui-kit'
import { useInitialFilters } from '@src/features/Goals/GoalsTab'
import Loader from '@src/components/CommonSC/Loader'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from '../Summary/useCalculatedDeliverablesRating'
import { useGoalsVisibilityBySummary } from '@src/features/Goals/useGoalsVisibilityBySummary'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useCreateGoal } from './hooks/useCreateGoal'

export const NoReviewsGoalsCollapsable = ({
  summary,
  employee,
  selectedPeriod,
  calculatedDeliverablesRating,
  lineManagerId,
}: {
  summary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
  employee: Pick<EmployeeInterface, 'id'>
  lineManagerId?: number
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
  selectedPeriod: PerformanceSelector
}) => {
  const [goalsStat, setGoalsStat] = useState<number>()
  const user = useSelector(selectUser)
  const [goalsTableCount, setGoalsTableCount] = useState<number | undefined>()
  const [kpiTableCount, setKpiTableCount] = useState<number | undefined>()
  const initialFilters = useInitialFilters(selectedPeriod)
  const { isFetching, isIndividualGoalsVisible, isTeamGoalsVisible } =
    useGoalsVisibilityBySummary(summary, initialFilters ?? [])
  const { isLoading: isCreateGoalPending, createGoal } = useCreateGoal()
  const team = summary?.team
  const gradesMap = summary?.grade_label_mapping

  const canAddGoals = user.id === lineManagerId

  if (!summary || !gradesMap) {
    return null
  }

  if (
    selectedPeriod.category === ReviewCycleCategory.Probation &&
    selectedPeriod.probation_reviews_deliverables_type === 'kpi'
  ) {
    return (
      <CollapsableTableWrapper
        icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
        headerTitle="Goals"
        headerStat={goalsStat}
        headerRating={calculatedDeliverablesRating?.rating}
        ratings={calculatedDeliverablesRating?.ratings}
        mapperFunc={calculatedDeliverablesRating?.mapper}
        gradesMap={calculatedDeliverablesRating?.gradesMap}
        count={kpiTableCount}
      >
        <EmployeeKpisCardTable
          isScrollable={false}
          setKpiTableCount={setKpiTableCount}
          gradesMap={gradesMap}
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      </CollapsableTableWrapper>
    )
  }
  return (
    <CollapsableTableWrapper
      icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
      headerTitle="Goals"
      headerStat={goalsStat}
      headerRating={calculatedDeliverablesRating?.rating}
      ratings={calculatedDeliverablesRating?.ratings}
      mapperFunc={calculatedDeliverablesRating?.mapper}
      gradesMap={calculatedDeliverablesRating?.gradesMap}
      completedReviews
      count={goalsTableCount}
    >
      <VStack width="100%">
        {isFetching && <Loader />}
        {isIndividualGoalsVisible && !isFetching && (
          <EmployeeGoalsCardTable
            setGoalsTableCount={setGoalsTableCount}
            gradesMap={gradesMap}
            setGoalsStat={setGoalsStat}
            noWidget
            employeeId={employee.id}
            cycle={selectedPeriod}
          />
        )}
        {isTeamGoalsVisible && !isFetching && (
          <TeamGoalsCardTable
            setGoalsTableCount={setGoalsTableCount}
            showBanner
            isPerformanceBanner
            gradesMap={gradesMap}
            cycle={selectedPeriod}
            team={team}
            noWidget
          />
        )}
        {selectedPeriod.category === ReviewCycleCategory.Probation &&
          canAddGoals &&
          Boolean(goalsTableCount && goalsTableCount > 0) && (
            <HStack p="s-16">
              <ActionButton
                useIcon="Plus"
                onClick={() => {
                  createGoal({ employeeId: employee.id, cycleId: selectedPeriod.id })
                }}
                pending={isCreateGoalPending}
              >
                Add probation goals
              </ActionButton>
            </HStack>
          )}
      </VStack>
    </CollapsableTableWrapper>
  )
}
